import { Image } from "@/modules/common/components";
import { Loader as RootLoader } from "@/root";
import { useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { Link, useFetcher, useRouteLoaderData } from "@remix-run/react";
import { HiBadgeCheck, HiOutlineExternalLink } from "react-icons/hi";
import { z } from "zod";

const postSchema = z.object({
  productId: z.string(),
  action: z.literal("upvote"),
});

type Props = {
  product: {
    id: string;
    name: string;
    slug: string;
    tagline: string;
    verified: boolean;
    tags: Array<{
      id: string;
      tag: {
        id: string;
        name: string;
        slug: string;
      };
    }>;
    deals: Array<{
      id: string;
    }>;
  };
};

export function Item({ product }: Props) {
  const root = useRouteLoaderData<RootLoader>("root");
  const fetcher = useFetcher();

  const [form, fields] = useForm({
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",
    constraint: getZodConstraint(postSchema),
    defaultValue: {
      productId: product.id,
    },
    onValidate: ({ formData }) => {
      return parseWithZod(formData, { schema: postSchema });
    },
  });

  return (
    <div key={product.id} className="relative group">
      <div className="absolute duration-500 group-hover:bg-neutral-50 -inset-4 rounded-xl" />

      <div className="relative z-10 transition-all duration-500">
        <div className="flex items-center gap-4">
          <div
            className="size-[50px] min-w-[50px] rounded-md overflow-hidden"
            style={{
              viewTransitionName: `logo-${product.id}`,
            }}
          >
            <Image
              src={`${root?.env.MEDIA_URL}/products/${product.id}/logo.png`}
              alt={product.name}
              className="w-full"
            />
          </div>

          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <h3
                className="flex items-center gap-1 text-base font-bold text-common-title"
                style={{
                  viewTransitionName: `name-${product.id}`,
                }}
              >
                <Link to={`/products/${product.slug}`} unstable_viewTransition>
                  <span className="absolute inset-x-0 bottom-0 -top-px" />

                  <span>{product.name}</span>
                </Link>

                <Link
                  to={`https://go.toplistly.com/${product.id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="z-10 hidden group-hover:inline"
                >
                  <HiOutlineExternalLink className="size-4" />
                </Link>

                {product.verified && (
                  <HiBadgeCheck className="text-emerald-600" />
                )}
              </h3>

              {product.deals.length > 0 && (
                <div className="px-2 py-1 text-xs font-medium rounded-lg text-emerald-600 bg-emerald-50">
                  {product.deals.length}{" "}
                  {product.deals.length === 1 ? "deal" : "deals"}
                </div>
              )}
            </div>

            <p
              className="mt-1 text-sm text-common-subtitle line-clamp-1"
              style={{
                viewTransitionName: `tagline-${product.id}`,
              }}
            >
              {product.tagline}
            </p>
          </div>

          {/*<Form
            form={form}
            fetcher={fetcher}
            method="post"
            className="z-10 hidden md:block"
          >
            <Form.Input meta={fields.productId} type="hidden" />

            <Form.Button
              isLoading={fetcher.state != "idle"}
              value="upvote"
              className="flex-col gap-1 h-[55px] w-[45px]"
            >
              <IoCaretUpOutline className="size-4" />
              <span className="text-xs">1</span>
            </Form.Button>
          </Form>*/}
        </div>

        <div className="flex flex-wrap gap-2 mt-4">
          {product.tags.map((tag) => (
            <span
              key={tag.id}
              className="px-2 py-1 text-xs font-medium duration-500 rounded-lg text-neutral-700 bg-neutral-50 hover:bg-neutral-100 whitespace-nowrap"
              style={{
                viewTransitionName: `tag-${tag.id}`,
              }}
            >
              {tag.tag.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
