import { Link } from "@remix-run/react";
import { FaSolarPanel } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa6";

export function Promotion() {
  return (
    <div className="relative group">
      <div className="absolute duration-500 group-hover:bg-neutral-50 -inset-4 rounded-xl" />

      <div className="relative z-10 transition-all duration-500">
        <div className="flex items-center gap-4">
          <div className="size-[50px] min-w-[50px] rounded-md overflow-hidden border border-common-border bg-white flex items-center justify-center">
            <FaQuestion />
          </div>

          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <h3 className="text-base font-bold text-common-title">
                <Link
                  to="mailto:info@toplistly.com?subject=Promote your product&body=Hello, I would like to promote my product on toplistly.com."
                  className="flex items-center gap-1"
                >
                  <span className="absolute inset-x-0 bottom-0 -top-px" />

                  <span>Promote your product</span>
                </Link>
              </h3>
            </div>

            <p className="mt-1 text-sm text-common-subtitle line-clamp-1">
              Your product can be advertise in this spot.
            </p>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mt-4">
          <span className="px-2 py-1 text-xs font-medium duration-500 rounded-lg text-amber-700 bg-amber-50 hover:bg-amber-100 whitespace-nowrap">
            Promoted
          </span>
        </div>
      </div>
    </div>
  );
}
